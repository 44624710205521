import { template as template_6406ec4dd7284529b8f6cd6964802f8d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_6406ec4dd7284529b8f6cd6964802f8d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
