import { template as template_f0e21517c1e04db590bbfc17b7a94049 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f0e21517c1e04db590bbfc17b7a94049(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
